import {useParams} from "react-router-dom";
import PageLayout from "../../components/MIT/PageLayout";
import {useCallback, useEffect, useRef, useState} from "react";
import {Button, Grid,} from "@mui/material";
import BottomActionBar from "../../components/MIT/BottomActionBar";

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckIcon from '@mui/icons-material/Check';
import Comments from "./comments/Comments";
import ExecutedTasks from "./tasks/ExecutedTasks";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CommonDetails from "./common-details/CommonDetails";
import ImageUploads from "./images/ImageUploads";
import Signature from "./signature/Signature";
import {useForm} from "react-hook-form";
import axiosInstance from "@/features/auth/authService";
import moment from "moment";
import _ from "lodash";
import PlantOrder from "@/types/PlantOrder";
import PlantOrderNotesModal from "@/features/workorders/plant-orders/PlantOrderNotesModal";
import PlantOrderCard from "@/features/workorders/plant-orders/PlantOrdersCard";
import Note from "@/types/Note";

interface Image {
    id: number;
    data: string;
}


interface WorkOrder {
    id: number;
    title: string;
    description: string;
    client_id: number;
    contact: string;
    phone: string;
    street: string;
    postal_code: string;
    city: string;
    houseNumber: string;
    contract: string;
    date_visit: string;
    date_last_visit: string;
    period: number;
    week: number;
    route_number: number;
    start_time: string;
    end_time: string;
    remarks_last_visit: string;
    planters_big: number;
    planters_small: number;
    number_of_planters: number;
    images: Image[];
    signature: string;
    contract_type: string;
    gve_count: number;
    customer_comment: string;
    reference: string;
    plant_orders: PlantOrder[],
    entity_uuid: string;
    mail_sent: boolean;
    confirmation_mail_address: string;
    uuid: string;
}

export default function WorkOrderDetails() {

    const [workOrder, setWorkOrder] = useState<WorkOrder>()
    const [mailDisabled, setMailDisabled] = useState<boolean>(false)
    const [bloomNotes, setBloomNotes] = useState<Note[] | null>(null);
    let {workOrderId} = useParams();
    const formRef = useRef<HTMLFormElement>(null)

    const {
        handleSubmit,
        register,
        control,
        reset,
        watch,
        formState: {errors, isDirty},
    } = useForm({
        shouldUnregister: true,
    })

    const alwaysWatching = watch()

    // Filled data
    const [signature, setSignature] = useState<string>('')

    useEffect(() => {
        if (!workOrderId) return;

        // get workorder data
        axiosInstance.get(`/work-orders/${workOrderId}/`).then(({data}) => {
            reset(data)
            setWorkOrder(dehydrateValues(data))
        })

    }, [workOrderId]);

    const refresh = () => {
        if (!workOrderId) return;
        axiosInstance.get(`/work-orders/${workOrderId}/`).then(({data}) => {
            reset(data)
            setWorkOrder(dehydrateValues(data))
        })
    }

    const saveWorkOrder = () => {
        formRef?.current?.requestSubmit();
    }

    const dehydrateValues = (data: any) => {
        const address = data.entity?.addresses?.find((address: any) => address.is_visit_address);

        setSignature(data.signature)
        // sort data.images by id
        data.images = data.images.sort((a: any, b: any) => a.id - b.id)

        return {
            id: data.id,
            title: data.name,
            description: data.name,
            client_id: data.entity.legacy_id ? data.entity.legacy_id : data.entity.id,
            contact: data.entity.primary_contact?.name_composite,
            phone: data.entity.primary_contact?.mobile_phone ?
                data.entity.primary_contact?.mobile_phone :
                data.entity.primary_contact?.office_phone,
            street: address?.street,
            postal_code: address?.postal_code,
            city: address?.city,
            houseNumber: address?.house_number,
            contract: data.contract,
            date_visit: data.date_visit,
            date_last_visit: data.date_last_visit,
            period: data.period,
            week: moment().week(),
            route_number: data.route_number,
            start_time: data.start_time,
            end_time: data.end_time,
            remarks_last_visit: data.remarks_last_visit,
            planters_big: data.planters_big,
            planters_small: data.planters_small,
            number_of_planters: data.planters_big + data.planters_small,
            images: data.images ?? [],
            signature: data.signature,
            contract_type: data.entity.contract_type?.value ?? '*Niet gevuld',
            gve_count: data.gve_count,
            customer_comment: data.customer_comment,
            reference: data.entity.reference ? data.entity.reference : (data.entity.legacy_id ? data.entity.legacy_id : data.entity.id),
            plant_orders: data.plant_orders,
            entity_uuid: data.entity_uuid,
            mail_sent: data.mail_sent,
            confirmation_mail_address: data.confirmation_mail_address,
            uuid: data.uuid
        }


    }

    const handleSignatureChange = (signature: string) => {
        setSignature(signature)
        axiosInstance.patch(`/work-orders/${workOrderId}/`, {
            signature: signature
        }).then(({data}) => {
        }).catch((error) => {
            console.log('error')
        })
    }

    const doSave = (values: any) => {
        console.log('saving')
        axiosInstance.patch(`/work-orders/${workOrderId}/`, {
            ...values
        }).then(({data}) => {
        }).catch((error) => {
            console.log('error')
        })
    }

    const plantOrderCommentClose = () => {
        setBloomNotes(null)
    }

    const saveWorkOrderDebounced = useCallback(_.debounce(doSave, 1000), [signature]);

    useEffect(() => {
        if (isDirty) {
            saveWorkOrderDebounced(alwaysWatching);
        }
    }, [alwaysWatching, isDirty, saveWorkOrderDebounced]);

    const startWorkOrder = useCallback(async () => {
        if (!window.confirm('Weet je zeker dat je deze werkorder wilt starten?')) return;
        axiosInstance.post(`/work-orders/${workOrderId}/start/`).then(({data}) => {
            saveWorkOrder();
            refresh();
        })

    }, [workOrderId, refresh])

    const finishWorkOrder = useCallback(() => {
        if (!window.confirm('Weet je zeker dat je deze werkorder wilt afronden?')) return;

        axiosInstance.post(`/work-orders/${workOrderId}/finish/`).then(({data}) => {
            saveWorkOrder();
            refresh();
        })

    }, [workOrderId])

    const mailWorkOrderConfirmation = useCallback(() => {
        if(!workOrder?.confirmation_mail_address) return alert('Er is geen mailadres gekoppeld waarnaar de bevestigingsmail gestuurd kan worden.');
        if(!window.confirm(`Wil je de klant een bevestigingsmail sturen? Deze mail wordt verstuurd naar: ${workOrder?.confirmation_mail_address}`)) return;

        setMailDisabled(true);

        axiosInstance.post(`/work-orders/${workOrderId}/send_email/`).then(({data}) => {
            saveWorkOrder();
            refresh();
        }).finally(() => setMailDisabled(false))

    }, [workOrderId, workOrder])

    const bottomBarContent = <>
        {workOrder && !workOrder?.start_time ?
            <Button aria-label={'test'} style={{color: '#fff', zIndex: '1000 !important'}} onClick={startWorkOrder}>
                <PlayArrowIcon sx={{width: '60px', height: '60px'}}/>
            </Button> :
            null}
        {workOrder && workOrder?.start_time && !workOrder?.end_time ?
            <Button aria-label={'test'} style={{color: '#fff', zIndex: '1000 !important'}} onClick={finishWorkOrder}>
                <CheckIcon sx={{width: '60px', height: '60px'}}/>
            </Button>
            : null}
        {workOrder?.start_time && workOrder.end_time && !workOrder.mail_sent && !mailDisabled ?
              <Button disabled={mailDisabled} aria-label={'test'} style={{color: '#fff', zIndex: '1000 !important'}} onClick={mailWorkOrderConfirmation}>
                <MailOutlineIcon sx={{width: '50px', height: '50px'}}/>
            </Button>
            : null}
        {workOrder?.start_time && workOrder.end_time && workOrder.mail_sent ? <span style={{color: "#fff"}}>Order afgewerkt</span> : null}
    </>

    return <PageLayout title={workOrder?.description ?? workOrderId ?? 'workorder'} backPressUrl={'/workorders'}
                       backButton={true} onBackPress={saveWorkOrder} hasBottomBar={true}>
        <div className={'container'} style={{fontSize: '1.1rem'}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CommonDetails workOrder={workOrder} refreshCallback={refresh}/>
                </Grid>

                <Grid item xs={12}>
                    <hr style={{color: 'black'}}/>
                </Grid>

                <Grid item xs={12}>
                    <Grid container>
                        {/* Bloom */}
                        <Grid item xs={12}>
                            <h3 style={{marginTop: 0}}>Bloei / Plantvervangingen</h3>
                            {workOrder?.plant_orders?.map((plantOrder: PlantOrder) => {
                                return (
                                    <PlantOrderCard
                                        key={plantOrder.id}
                                        plantOrder={plantOrder}
                                        onNotesClick={setBloomNotes}
                                    />
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <form ref={formRef} style={{padding: '16px'}} onSubmit={handleSubmit(doSave)}>
                    <Comments control={control} errors={errors} register={register}/>
                    <ExecutedTasks control={control}/>
                    <ImageUploads existingImages={workOrder?.images ?? []} workOrderId={workOrderId}/>
                    <br/>
                    <br/>
                    <Signature initialSignature={workOrder?.signature ?? ''} onChange={handleSignatureChange}/>
                </form>

            </Grid>
            <div style={{height: '100px'}}>
                &nbsp;
                {/*  Spacing div  */}
            </div>
        </div>
        <PlantOrderNotesModal notes={bloomNotes} onClose={plantOrderCommentClose}/>
        <BottomActionBar>
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center'
            }}>
                {bottomBarContent}
            </div>
        </BottomActionBar>

    </PageLayout>
}